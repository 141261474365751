import { Box, Divider } from "@material-ui/core";
import React from "react";

export function HorizontalDivider() {
  return (
    <Box pt={3} pb={3} pl="20%" pr="20%" width="100%">
      <Divider orientation="horizontal" />
    </Box>
  );
}
