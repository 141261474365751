import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faLock } from "@fortawesome/pro-light-svg-icons";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ILoginPayload } from "../src/api/types/authentication";
import { ErrorsBox } from "../src/components/ErrorsBox";
import { HorizontalDivider } from "../src/components/HorizontalDivider";
import Link from "../src/components/Link";
import MarketingModule from "../src/components/MarketingModule";
import SEO from "../src/components/SEO";
import SmallHeader from "../src/components/SmallHeader";
import SquareIcon from "../src/components/SquareIcon";
import { VerticalSpacer } from "../src/components/utils/spacers";
import { useErrors } from "../src/hooks/useErrors";
import { useIsLoading } from "../src/hooks/useIsLoading";
import useNoAuth from "../src/hooks/useNoAuth";
import { apiActions } from "../src/store/modules/api";
import { authActions } from "../src/store/modules/auth";
import { ApiAuthKeys } from "../src/store/modules/auth/keys";
import { spreadField } from "../src/utils/formik";
import { gaEvent } from "../src/utils/ga";
import { LoginSchema } from "../src/utils/validation/auth";

const namespaces = ["login", "forms", "errors", "reset_password"];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    minHeight: "100vh",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  leftContainer: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
  left: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 600,
    padding: "2rem",
  },
  loginForm: {
    maxWidth: 354,
  },
  rightContainer: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    backgroundColor: theme.palette.grey[100],
  },
  title: {
    fontSize: "32px",
    fontWeight: 600,
  },
  brand: {
    color: theme.palette.primary.main,
  },
}));

export default function LogIn() {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation(namespaces);

  const isLoading = useIsLoading(ApiAuthKeys.login);
  const errors = useErrors(ApiAuthKeys.login);

  useNoAuth();

  const action = router.query.action as string | undefined;

  useEffect(() => {
    dispatch(apiActions.reset(ApiAuthKeys.login));
  }, []);

  const formik = useFormik<ILoginPayload>({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(authActions.login(values));

      gaEvent({
        category: "LogIn",
        action: "LogIn",
        label: "LogIn",
      });
    },
    validationSchema: LoginSchema,
    validateOnMount: false,
    validateOnChange: false,
  });

  return (
    <div className={classes.container}>
      <SEO title="login" />
      <div className={classes.leftContainer}>
        <div className={classes.left}>
          <SmallHeader />

          <VerticalSpacer size={12} />

          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Typography
              className={classes.title}
              variant="h3"
              component="h1"
              gutterBottom
            >
              {t("login:login")}{" "}
              <span className={classes.brand}>{t("login:toPropty")}</span>
            </Typography>
          </Box>

          <VerticalSpacer size={2} />

          <Typography gutterBottom color="textSecondary">
            {t("login:subtitle")}
          </Typography>

          <VerticalSpacer size={3} />

          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className={classes.loginForm}
          >
            <TextField
              type="email"
              fullWidth
              placeholder={t("forms:email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SquareIcon icon={faEnvelope as IconProp} color="#C2C5CD" />
                  </InputAdornment>
                ),
              }}
              {...spreadField(formik, "email", t)}
            />

            <VerticalSpacer size={2} />

            <TextField
              type="password"
              fullWidth
              placeholder={t("forms:password")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SquareIcon icon={faLock as IconProp} color="#C2C5CD" />
                  </InputAdornment>
                ),
              }}
              {...spreadField(formik, "password", t)}
            />

            <VerticalSpacer size={2} />

            <Typography gutterBottom color="textSecondary">
              {t("login:forgotPassword")}{" "}
              <Link href="/forgot-password">
                {t("login:forgotPasswordLink")}
              </Link>
            </Typography>

            <VerticalSpacer size={4} />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              {t("login")}
            </Button>

            <VerticalSpacer size={2} />

            {isLoading && (
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            )}

            <Typography gutterBottom color="textSecondary">
              {t("login:createAccount")}{" "}
              <Link href="/register">{t("login:createAccountLink")}</Link>
            </Typography>

            <HorizontalDivider />

            <VerticalSpacer size={2} />

            <Link href="/meeting">
              <Button variant="outlined" color="primary" size="large" fullWidth>
                {t("login:joinMeeting")}
              </Button>
            </Link>

            <VerticalSpacer size={2} />

            <ErrorsBox errors={errors} boxProps={{ mb: 1 }} />

            {action === "reset-password" && (
              <Alert severity="success">{t("reset_password:success")}</Alert>
            )}
          </form>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <MarketingModule />
      </div>
    </div>
  );
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        ...namespaces,
        ...MarketingModule.namespaces,
      ])),
    },
  };
}
