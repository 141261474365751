import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectHasSession } from "../store/modules/user/selectors";

export default function useNoAuth() {
  const hasSession = useSelector(selectHasSession);
  const router = useRouter();

  useEffect(() => {
    if (hasSession) {
      router.push("/");
    }
  }, [hasSession]);
}
